import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Services from "./Services";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

import s2i1 from './image/s2/we.jpg';
import s2i2 from './image/s2/ccer.jpg';
import s2i3 from './image/s2/xxccx.jpg';
import i1 from './image/s3/i1.jpg';
import i2 from './image/s3/i2.jpg';
import i3 from './image/s3/i3.jpg';
import img41 from './image/s4/13.png';
import img42 from './image/s4/f6.jpg';
import vc1 from './image/vehicle/c1.jpg';
import vc2 from './image/vehicle/c2.jpg';
import vv1 from './image/vehicle/v1.jpg';
import vv2 from './image/vehicle/v2.jpg';

import desp1 from './image/Destination/Yala_1.jpg';
import desp2 from './image/Destination/Weligama_1.jpg';
import desp3 from './image/Destination/Unawatuna_1.jpg';
import desp4 from './image/Destination/Hikkaduwa_3.jpg';
import desp5 from './image/Destination/GalleFort_5.jpg';
import desp6 from './image/Destination/Mirissa_2.jpg';
import desp7 from './image/Destination/Tangalle_3.jpg';
import desp8 from './image/Destination/Bentota_2.jpg';
import desp9 from './image/Destination/Udawalawe_2.jpg';

import icon1 from './image/icons/back-to-up.jpg';
import icon2 from './image/icons/tripadvisor.png';
import icon3 from './image/icons/whatsapp.png';
import icon4 from './image/icons/email.png';

import ssi1 from './image/SlideShow/1.jpg';
import ssi2 from './image/SlideShow/2.jpg';
import ssi3 from './image/SlideShow/3.jpg';
import ssi4 from './image/SlideShow/4.jpg';
import ssi5 from './image/SlideShow/5.jpg';
import ssi6 from './image/SlideShow/6.jpg';
import ssi7 from './image/SlideShow/7.jpg';
import ssi8 from './image/SlideShow/8.jpg';
import ssi9 from './image/SlideShow/9.jpg';
import ssi10 from './image/SlideShow/10.jpg';

export default function App() {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const [data, setData] = useState({
    'name': '',
    'email': '',
    'contact': '',
    'nop': '',
    'arivel_date': '',
    'pPlace': '',
    'pTime': '',
    'dPlace': '',
    'message': ''
  });
  const [formClass, setFormClass] = useState({
    'clsName': 'form-control brx',
    'clsEmail': 'form-control brx',
    'clsContact': 'form-control brx',
    'clsNop': 'form-control brx',
    'clsArivel_date': 'form-control brx',
    'clsPPlace': 'form-control brx',
    'clsPTime': 'form-control brx',
    'clsDPlace': 'form-control brx',
    'clsMessage': 'form-control brx'
  });
  const [today, setToday] = useState(new Date().toISOString().slice(0, 10));
  const [show, setShow] = useState(true);
  const [dtbox1, setDtbox1] = useState("text");
  const [dtbox2, setDtbox2] = useState("text");
  const [modalShow, setModalShow] = useState(false);
  const [modalShowXL, setModalShowXL] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const slideImages = [
    {
      url: ssi1,
      caption: 'Galle Fort'
    },
    {
      url: ssi2,
      caption: 'Yala National Park'
    },
    {
      url: ssi3,
      caption: 'Udawalawa National Park'
    },
    {
      url: ssi4,
      caption: ''
    },
    {
      url: ssi5,
      caption: 'Nine Arch Bridge'
    },
    {
      url: ssi6,
      caption: ''
    },
    {
      url: ssi7,
      caption: ''
    },
    {
      url: ssi8,
      caption: ''
    },
    {
      url: ssi9,
      caption: 'Unawatuna Beach'
    },
    {
      url: ssi10,
      caption: 'Mulkirigala Temple'
    }
  ];

  //NavBar function
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar());

      return () => {
        window.removeEventListener('scroll', controlNavbar());
      };
    }
  }, [lastScrollY]);

  function controlNavbar(){
    if (typeof window !== 'undefined') {
      if (window.scrollY > lastScrollY) {
        setShow(false);
      } else {
        setShow(true);
      }

      setLastScrollY(window.scrollY);
    }
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function handle(e){
    e.preventDefault();
    const newData = {...data};
    newData[e.target.id] = e.target.value;
    setData(newData);
  }

  function onChangeCss(element){
    let temp = {...formClass};
    switch(element){
      case "clsName": temp.clsName = "form-control brx"; break;
      case "clsEmail": temp.clsEmail = "form-control brx"; break;
      case "clsContact": temp.clsContact = "form-control brx"; break;
      case "clsNop": temp.clsNop = "form-control brx"; break;
      case "clsArivel_date": temp.clsArivel_date = "form-control brx"; break;
      case "clsPPlace": temp.clsPPlace = "form-control brx"; break;
      case "clsPTime": temp.clsPTime = "form-control brx"; break;
      case "clsDPlace": temp.clsDPlace = "form-control brx"; break;
      case "clsMessage": temp.clsMessage = "form-control brx"; break;
      default: break;
    }
    setFormClass(temp);
    console.error(temp);
  }

  function sendMail(e){
    e.preventDefault();
    if(formValidations()){
      Services.mail(data)
      .then(({data}) =>{
        setModalShow(false);
        setData({
          'name': '',
          'email': '',
          'contact': '',
          'nop': '',
          'arivel_date': '',
          'pPlace': '',
          'pTime': '',
          'dPlace': '',
          'message': ''
        });
        setFormClass({
          'clsName': 'form-control brx',
          'clsEmail': 'form-control brx',
          'clsContact': 'form-control brx',
          'clsNop': 'form-control brx',
          'clsArivel_date': 'form-control brx',
          'clsPPlace': 'form-control brx',
          'clsPTime': 'form-control brx',
          'clsDPlace': 'form-control brx',
          'clsMessage': 'form-control brx'
        });
        alert("Reservation successfully. We will contact you soon.");
        // console.log(data);
      })
      .catch(({response})=>{
        setModalShow(false);
        alert("Something went wrong!");
        // console.log(response);
      })
    }
  }

  function formValidations(){
    let isvalidFunc = true;
    var val = {...formClass};

    // Valid Name
    if(data.name == null || data.name == ""){
      val.clsName = "form-control brx is-invalid";
      isvalidFunc = false;
    }
    else{
      val.clsName = "form-control brx is-valid";
    }

    // Valid Email
    if(data.email == null || data.email == "" || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email))){
      val.clsEmail = "form-control brx is-invalid";
      isvalidFunc = false;
    }
    else{
      val.clsEmail = "form-control brx is-valid";
    }

    // Valid Contact
    if(data.contact == null || data.contact == "" ){
      val.clsContact = "form-control brx is-invalid";
      isvalidFunc = false;
    }
    else{
      val.clsContact = "form-control brx is-valid";
    }

    // Valid NOP
    if(data.nop == null || data.nop == ""){
      val.clsNop = "form-control brx is-invalid";
      isvalidFunc = false;
    }
    else{
      val.clsNop = "form-control brx is-valid";
    }

    // Valid Arivel_date
    if(data.arivel_date == null || data.arivel_date == "" || data.arivel_date < today){
      val.clsArivel_date = "form-control brx is-invalid";
      isvalidFunc = false;
    }
    else{
      val.clsArivel_date = "form-control brx is-valid";
    }

    // Valid PPlace
    if(data.pPlace == null || data.pPlace == ""){
      val.clsPPlace = "form-control brx is-invalid";
      isvalidFunc = false;
    }
    else{
      val.clsPPlace = "form-control brx is-valid";
    }

    // Valid PTime
    if(data.pTime == null || data.pTime == ""){
      val.clsPTime = "form-control brx is-invalid";
      isvalidFunc = false;
    }
    else{
      val.clsPTime = "form-control brx is-valid";
    }

    // Valid DPlace
    if(data.dPlace == null || data.dPlace == ""){
      val.clsDPlace = "form-control brx is-invalid";
      isvalidFunc = false;
    }
    else{
      val.clsDPlace = "form-control brx is-valid";
    }

    // Valid Message
    if(data.message == null || data.message == ""){
      val.clsMessage = "form-control brx is-invalid";
      isvalidFunc = false;
    }
    else{
      val.clsMessage = "form-control brx is-valid";
    }

    setFormClass(val);

    // Return Validations
    if(isvalidFunc == true){
      return true;
    }
    else{
      return false;
    }
  }

  function showHeader(){
    if(Number(windowDimensions.height)>Number(windowDimensions.width)){
      return(
        <>
            <div className="text-center">
                <div className="d-flex justify-content-center">
                    <p className={"display-1 sign text-center"}>
                        <span className='outglow-x' style={{color: "#fff", fontWeight: "400"}}>Tangalle </span>
                    </p>
                </div>
                <div className="d-flex justify-content-center">
                    <p className={"display-1 sign text-center"}>
                        <span className='outglow-x' style={{color: "#fdb813", paddingInline: "30px", fontWeight: "450"}}>Royal </span>
                        <span className='outglow-x' style={{color: "#fff", fontWeight: "400"}}>Taxi</span>
                    </p>
                </div>
            </div>
        </>
      );
    }
    else{
      return(
        <>
            <div className="d-flex justify-content-center">
                <p className={"display-x sign"}>
                    <span className='outglow-x' style={{color: "#fff", fontWeight: "400"}}>Tangalle </span>
                    <span className='outglow-x' style={{color: "#fdb813", paddingInline: "30px", fontWeight: "450"}}>Royal </span>
                    <span className='outglow-x' style={{color: "#fff", fontWeight: "400"}}>Taxi</span>
                </p>
            </div>
        </>
      );
    }
  }

  function showSlideShow(){
    if(Number(windowDimensions.height)>Number(windowDimensions.width)){
      return(
        <>
          <div className="slide-container">
            <Slide>
            {slideImages.map((slideImage, index)=> (
                <div className="each-slide" key={index}>
                  <img alt="" src={slideImage.url} style={{'height': 'auto', 'width': '100%'}} /> 
                </div>
              ))} 
            </Slide>
          </div>
        </>
      );
    }
    else{
      return(
        <>
          <div className="slide-container">
            <Slide>
            {slideImages.map((slideImage, index)=> (
                <div className="each-slide" key={index}>
                  <div style={{'backgroundImage': `url(${slideImage.url})`, 'height': '450px', 'maxWidth': '100%', 'backgroundRepeat': 'no-repeat', 'backgroundPosition': 'center center'}}>
                    <span></span>
                  </div>
                </div>
              ))} 
            </Slide>
          </div>
        </>
      );
    }
  }

  function showContacts(){
    if(Number(windowDimensions.height)>Number(windowDimensions.width)){
      return(
        <>
          <p className='p0'>CALL US ANYTIME AT:</p>
          <p className='p2'>+94 74 322 9005</p>
        </>
      );
    }
    else{
      return(
        <>
          <p className='p0'>CALL US ANYTIME AT:</p>
          <p className='p1'>+94 74 322 9005</p>
        </>
      );
    }
  }

  return (
    <div>
      {/** Nav bar */}
      <div>
        <header>
          <nav className={`navbar navbar-expand-lg navbar-light fixed-top mask-custom shadow-0 ${show && 'hidden'}`} aria-label="Third navbar example">
            <div className="container">
              <p className="navbar-brand" onClick={() => ref1.current?.scrollIntoView({behavior: 'smooth'}) }>
                <span className="outglow outglow-y" style={{color: "#fff"}}>Tangalle </span>
                <span className="outglow outglow-y" style={{color: "#fdb813"}}>Royal </span>
                <span className="outglow outglow-y" style={{color: "#fff"}}>Taxi</span>
              </p>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample03" aria-controls="navbarsExample03" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbarsExample03">
                <ul className="navbar-nav me-auto"></ul>

                <ul className="navbar-nav me-auto mb-2 mb-sm-0">
                  <li className="nav-item pt-2">
                    <p className="nav-link active text-dark" onClick={() => ref1.current?.scrollIntoView({behavior: 'smooth'}) }>Home</p>
                  </li>
                  <li className="nav-item pt-2">
                    <p className="nav-link active text-dark" onClick={() => ref2.current?.scrollIntoView({behavior: 'smooth'}) }>Quick Contact</p>
                  </li>
                  <li className="nav-item pt-2">
                    <p className="nav-link active text-dark" onClick={() => ref3.current?.scrollIntoView({behavior: 'smooth'}) }>Services</p>
                  </li>
                  <li className="nav-item pt-2">
                    <p className="nav-link active text-dark" onClick={() => setModalShowXL(true) }>Destinations</p>
                  </li>
                  <li className="nav-item pt-2">
                    <p className="nav-link active text-dark" onClick={() => ref4.current?.scrollIntoView({behavior: 'smooth'}) }>Hires</p>
                  </li>
                  <li className="nav-item pt-2">
                    <p className="nav-link active text-dark" onClick={() => ref5.current?.scrollIntoView({behavior: 'smooth'}) }>About Us</p>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </div>

      {/** Header */}
      <div>
        <section ref={ref1}>
          <div id="intro" className="bg-image vh-100 bgimg">
            <div className='d-flex justify-content-center' style={{paddingTop: "150px"}}>
              {showHeader()}
            </div>

            <div className="mask">
              <div className={Number(windowDimensions.height)>Number(windowDimensions.width)?"headerBoxCenter":"headerBoxLeft"}>
                <div className='text-box-with-shadow'>
                  {showContacts()}
                </div>
                <div>
                  <button type='button' className='btn btn-lg btn-secondary' onClick={() => setModalShow(true)}><i className="bi bi-play-circle"></i>&nbsp;Quick Contact</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/** Section 2 */}
      <div className='py-5 bgimg2' style={{ backgroundColor: "#eee" }} ref={ref3}>
        <div className='container'>
          {showSlideShow()}
        </div>
      </div>

      {/** Section 2 */}
      <div className='container py-5' ref={ref3}>
        <div className='row raw-cols-auto justify-content-center'>
          <div className={Number(windowDimensions.height)>Number(windowDimensions.width)?"col my-3":"col-4"}>
            <div className='d-flex justify-content-center'>
              <div className="card shadow-lg" style={{width: "288px", backgroundColor: "#fdb813"}}>
                <img alt="" src={s2i1} className="card-img-top" style={{padding: "20px"}} />
                <div className="card-body">
                  <h5 className="card-title text-center txtLowWeigthSet"><strong className='txtHighWeigthSet'>FAST &</strong> SAFE</h5>
                  <p className="card-text"></p>
                </div>
              </div>
            </div>
          </div>

          <div className={Number(windowDimensions.height)>Number(windowDimensions.width)?"col my-3":"col-4"}>
            <div className='d-flex justify-content-center'>
              <div className="card shadow-lg" style={{width: "288px", backgroundColor: "#fdb813"}}>
                <img alt="" src={s2i2} className="card-img-top" style={{padding: "20px"}} />
                <div className="card-body">
                  <h5 className="card-title text-center txtLowWeigthSet"><strong className='txtHighWeigthSet'>BEST</strong> SERVICE</h5>
                  <p className="card-text"></p>
                </div>
              </div>
            </div>
          </div>

          <div className={Number(windowDimensions.height)>Number(windowDimensions.width)?"col my-3":"col-4"}>
            <div className='d-flex justify-content-center'>
              <div className="card shadow-lg" style={{width: "288px", backgroundColor: "#fdb813"}}>
                <img alt="" src={s2i3} className="card-img-top" style={{padding: "20px"}} />
                <div className="card-body">
                  <h5 className="card-title text-center txtLowWeigthSet"><strong className='txtHighWeigthSet'>FAIR</strong> CHARGES</h5>
                  <p className="card-text"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/** Section 3 */}
      <div className='container pb-5'>
        <section className="" id="">
          <div className='row'>
            <div className='col'>
              <img alt="" src={i1} className={Number(windowDimensions.height)>Number(windowDimensions.width)?"d-flex justify-content-center s3imageX":"s3image"}/>
            </div>
            <div className='col' style={{ maxWidth:"400px" }}>
              <div className={Number(windowDimensions.height)>Number(windowDimensions.width)?"s3imageX":"s3image"}>
                <p className='p3'>Taxi transfers from and to Airport</p><br/>
                <div className='yellowLine1'></div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col' style={{ maxWidth:"400px" }}>
              <div className={Number(windowDimensions.height)>Number(windowDimensions.width)?"s3imageX":"s3image"}>
                <p className='p4'> &nbsp;&nbsp;&nbsp;
                  We are committed to serving you by meeting all your ground transportation needs. 
                  There are different options available for your selection when you are planning your trip from Airport to the destination of your choice. 
                  We offer transportation via cars, vans and taxi services.
                </p><br/>
              </div>
            </div>
            <div className='col'>
              <img src={i2} alt='' className={Number(windowDimensions.height)>Number(windowDimensions.width)?"d-flex justify-content-center s3imageX":"s3image"}/>
            </div>
          </div>

          <div className='yellowLine2'></div>

          <div className='row'>
            <div className='col' style={{ maxWidth:"400px" }}>
              <img alt="" src={i3} className={Number(windowDimensions.height)>Number(windowDimensions.width)?"d-flex justify-content-center s3imageX":"s3image"}/>
            </div>
            <div className='col'>
              <div className={Number(windowDimensions.height)>Number(windowDimensions.width)?"s3imageX":"s3image"}>
                <p className='lineSegment2' onClick={() => setModalShowXL(true)}>
                  Mainly the places where we provide services.... 
                </p><br/>
                <div className="d-flex justify-content-center">
                  <button type="button" className="btn btn-lg btn-dark py-3 px-5" onClick={() => setModalShowXL(true)}>See More...</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/** Section 4 */}
      <div>
        <div className='yellowBG' ref={ref5}>
          <div className="container">
            <div className="row row-col-auto">
              <div className="col">
                <img alt="" src={img41} className="s4image1"/>
              </div>
              <div className="col align-self-end">
                <div>
                  <h3>Safari Tours.</h3>
                  <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Sri Lanka is Asia's foremost wildlife destination and probably the best outside Africa. 
                    In fact, Sri Lanka has been recognized as one of the ten global bio diverse hotspots in the world. 
                    Beautiful Sri Lanka, situated in the tropical waters of the Indian Ocean is a relatively small island 
                    yet blessed with some of the world's greatest natural treasures that are beyond comparison.
                  </p>
                </div>
                <img alt="" src={img42} className='s4image2' />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/** Section 5 */}
      <div className='yellowBG'>
        <div className="container">
          <div className="row raw-cols-auto justify-content-center">
            <div className="row">
              <div className="col">
                <div className={Number(windowDimensions.height)>Number(windowDimensions.width)?"aboutboxX":"aboutbox"}>
                  <p className="h2 text-center"><b>TANGALLE ROYAL TAXI</b></p>
                  <br/>
                  <p className="h4">
                    We offer best and safe customer service and highly personalized service in <b>island wide</b>.<br/> You can instantly book any taxi or cab service with entire rates.<br/> Tangalle Royal Taxi facilitates you with our <b>24hrs</b>                                service throughout <b>365 days.</b> <br/> We have comfy vehicles with a good condition that feel you worth.
                  </p>
                  <br/>
                  <p className="h2 text-center"><b>WHAT WE OFFER?</b></p>
                  <br/>
                  <p className="h4">
                    Budget taxi and cab service, rent a car with a driver, hire a van with a driver & pvt airport transfers.
                  </p>
                </div>
              </div>

              <div className="col">
                <div className={Number(windowDimensions.height)>Number(windowDimensions.width)?"abouttextX":"abouttext"}>
                  <p className="h2 text-center"><b>WHY CHOOSE US? </b></p>
                  <br/>
                  <p className="h3">
                    We are the best solution for you to get around sri lanka cheapy and safely from tangell.
                  </p><br/>
                  <p className="h4">
                    <b>Best rates:</b> Best rates and lowest price in town with the excellent quality service.
                  </p>
                  <p className="h4">
                    <b>Wide range:</b> We have a wide range of budget cars, econmy cars, vans and luxury cars and buses for any transport or tour requirements with experienced driver.
                  </p>
                  <p className="h4">
                    <b>Safe & reliable:</b> Our service is a premium cab service operating in and around tangelle well reputed name for more than 10 years.
                  </p>
                  <p className="h4">
                    <b>Experienced drivers:</b> Not only we have well maintained fleet of vehicles but also we have experienced having track records for your safety.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/** Section 6 */}
      <div>
        <div className="container py-5" ref={ref4}>
          <div className="row row-cols-auto justify-content-center">
            <div className="card yellowBGX my-3 mx-2 p-3" style={{width: "18rem"}}>
                <img alt="" src={vc1} className="card-img-top imgbr" />
                <div className="card-body">
                    <p className="card-text">We have Budget Taxis <br/>(Max 3 Passengers)</p>
                </div>
            </div>

            <div className="card yellowBGX my-3 mx-2 p-3" style={{width: "18rem"}}>
                <img alt="" src={vv1} className="card-img-top imgbr"/>
                <div className="card-body">
                    <p className="card-text">We have KDH Flat Roof <br/>(Passengers 3+)</p>
                </div>
            </div>

            <div className="card yellowBGX my-3 mx-2 p-3" style={{width: "18rem"}}>
                <img alt="" src={vc2} className="card-img-top imgbr"/>
                <div className="card-body">
                    <p className="card-text">We have Budget Taxis <br/>(Max 3 Passengers)</p>
                </div>
            </div>

            <div className="card yellowBGX my-3 mx-2 p-3" style={{width: "18rem"}}>
                <img alt="" src={vv2} className="card-img-top imgbr"/>
                <div className="card-body">
                    <p className="card-text">We have KDH High Roof <br/>(Passengers 3+)</p>
                </div>
            </div>
          </div>
        </div>
      </div>

      {/** Footer */}
      <div>
        <footer className="bggray text-center text-lg-start" ref={ref2}>
          <div className="container p-4">
            <div className="row">
              <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
                <h5 className="text-uppercase"><b>GET IN TOUCH</b></h5>
                <p>
                  <i>We can ensure reliability, low cost fares and most important, with safety and comfort in mind.</i>
                </p>

                <h5 className="text-uppercase"><b>OUR TOP SERVICES</b></h5>
                <p>
                  ✔  Local transfers <br/>
                  ✔  Airport Transfers <br/>
                  ✔  Excursions and Tours <br/>
                </p>
              </div>
              <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
                <div>
                  <h5 className="text-uppercase"><b>CALL US</b></h5>
                  <p>
                    +94 74 322 9005
                  </p>
                </div>
                <div>
                  <h5 className="text-uppercase"><b>MAIL US</b></h5>
                  <p>
                    tangalleroyaltaxi@gmail.com
                  </p>
                </div>
                <div>
                  <h5 className="text-uppercase"><b>LOCATION</b></h5>
                  <p>
                    Tangalle, Sri Lanka. 82200
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center p-3 bggrayX">
            © 2022 Copyright: &nbsp;
            <a className="text-dark" href="http://www.tangalleroyaltaxi.com/">Tangalle Royal Taxi</a>
          </div>
        </footer>
      </div>

      {/** Floating Button */}
      <div className="position-relative">
        <div className="f1" style={{ position: "fixed", float: "right", bottom: "15px", right: "2px", width: "60px" }}>
            <img alt="" src={icon1} width="40px" onClick={() => ref1.current?.scrollIntoView({behavior: 'smooth'}) }/>
        </div>
        <div className="f2" style={{ position: "fixed", float: "right", bottom: "70px", right: "2px", width: "60px" }}>
            <a href=""><img alt="" src={icon2} width="40px" /></a>
        </div>
        <div className="f3" style={{ position: "fixed", float: "right", bottom: "125px", right: "2px", width: "60px" }}>
            <a href="https://wa.me/message/5VWPBZR5V4CMK1"><img alt="" src={icon3} width="40px" /></a>
        </div>
        <div className="f4" style={{ position: "fixed", float: "right", bottom: "180px", right: "2px", width: "60px" }}>
            <a href="mailto:tangalleroyaltaxi@gmail.com"><img alt="" src={icon4} width="40px" /></a>
        </div>
      </div>

      {/** Recieve Model */}
      <div>
        <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Reserve Your Seat
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="row row-cols-auto justify-content-center">
                <div className="row my-1">
                  <div className="col has-validation">
                    <div className="form-floating">
                      <input type="text" className={formClass.clsName} name="Name" id="name" onChange={(e) => {handle(e); onChangeCss("clsName");}} placeholder="Name" required/>
                      <label htmlFor="name">Name</label>
                      <div className="invalid-feedback">Please enter valid name.</div>
                      <div className="valid-feedback">Name validated.</div>
                    </div>
                  </div>
                </div>
                <div className="row my-1">
                  <div className="col">
                    <div className="form-floating">
                      <input type="email" className={formClass.clsEmail} name="Email" id="email" onChange={(e) => {handle(e); onChangeCss("clsEmail");}} placeholder="E-Mail" required/>
                      <label htmlFor="email">E-mail</label>
                      <div className="invalid-feedback">Please enter valid email. Eg: name@domain.com</div>
                      <div className="valid-feedback">Email validated.</div>
                    </div>
                  </div>
                </div>
                <div className="row my-1">
                  <div className="col">
                    <div className="form-floating">
                      <input type="text" className={formClass.clsContact} name="Contact" id="contact" onChange={(e) => {handle(e); onChangeCss("clsContact");}} placeholder="Contact Number" required/>
                      <label htmlFor="contact">Contact</label>
                      <div className="invalid-feedback">Please enter valid contact. Eg: +94771234567</div>
                      <div className="valid-feedback">Contact validated.</div>
                    </div>
                  </div>
                </div>
                <div className="row my-1">
                  <div className="col">
                    <div className="form-floating">
                      <input type="number" className={formClass.clsNop} name="No_of_Passengers" id="nop" onChange={(e) => {handle(e); onChangeCss("clsNop");}} placeholder="Contact" min="1" required/>
                      <label htmlFor="nop">Number of Passengers</label>
                      <div className="invalid-feedback">Please enter valid number of people.</div>
                      <div className="valid-feedback">Number of people validated.</div>
                    </div>
                  </div>
                </div>
                <div className="row my-1">
                  <div className="col">
                    <div className="form-floating">
                      <input type={dtbox1} className={formClass.clsArivel_date} name="Arivel_date" id="arivel_date" onChange={(e) => {handle(e); onChangeCss("clsArivel_date");}} onFocus={() => setDtbox1("date")} onBlur={() => setDtbox1("text")} min={today} placeholder="Pickup date" required/>
                      <label htmlFor="arivel_date">Arivel Date</label>
                      <div className="invalid-feedback">Please enter valid arivel date.</div>
                      <div className="valid-feedback">Arivel date validated.</div>
                    </div>
                  </div>
                </div>
                <div className="row my-1">
                  <div className="col">
                    <div className="form-floating">
                      <input type="text" className={formClass.clsPPlace} name="Pickup_Place" id="pPlace" onChange={(e) => {handle(e); onChangeCss("clsPPlace");}} placeholder="Pickup Place" required/>
                      <label htmlFor="pPlace">Pickup Place</label>
                      <div className="invalid-feedback">Please enter valid pickup place.</div>
                      <div className="valid-feedback">Pickup place validated.</div>
                    </div>
                  </div>
                </div>
                <div className="row my-1">
                  <div className="col">
                    <div className="form-floating">
                      <input type={dtbox2} className={formClass.clsPTime} name="Pickup_Time" id="pTime" onChange={(e) => {handle(e); onChangeCss("clsPTime");}} onFocus={() => setDtbox2("time")} onBlur={() => setDtbox2("text")} placeholder="Pickup Time" required/>
                      <label htmlFor="pTime">Pickup Time</label>
                      <div className="invalid-feedback">Please enter valid pickup time.</div>
                      <div className="valid-feedback">Pickup time validated.</div>
                    </div>
                  </div>
                </div>
                <div className="row my-1">
                  <div className="col">
                    <div className="form-floating">
                      <input type="text" className={formClass.clsDPlace} name="Drop_Place" id="dPlace" onChange={(e) => {handle(e); onChangeCss("clsDPlace");}} placeholder="Drop Place" required/>
                      <label htmlFor="dPlace">Drop Place</label>
                      <div className="invalid-feedback">Please enter valid drop place.</div>
                      <div className="valid-feedback">Drop place validated.</div>
                    </div>
                  </div>
                </div>
                <div className="row my-1">
                  <div className="col">
                    <div className="form-floating">
                      <textarea className={formClass.clsMessage} name="Message" id="message" onChange={(e) => {handle(e); onChangeCss("clsMessage");}} placeholder="Enter your message" required></textarea>
                      <label htmlFor="message">Enter your message</label>
                      <div className="invalid-feedback">Please enter valid message.</div>
                      <div className="valid-feedback">Message validated.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="row my-1">
                  <div className="col">
                    <button type="button" className="btn btn-primary btn-lg brx" onClick={(e) => sendMail(e)}>Send</button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {/** Locations Model */}
      <div>
        <Modal show={modalShowXL} onHide={() => setModalShowXL(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <div className='display-5 text-secondary text-center'>Locations for our services.</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='py-4 px-5'>
              <div className="grid-itemA item1 ">
                <h2>Yala</h2>
                <div className="row">
                  <div className="col-sm-5">
                    <img alt="" src={desp1} className={Number(windowDimensions.height)>Number(windowDimensions.width)?"rounded mx-auto d-block my-4 modelImageWeb":"rounded mx-auto d-block my-4 modelImageMobile"}/>
                  </div>
                  <div className="col-md-7">
                    <p className='fs-5'>
                      Yala national park is the second largest national park in Sri Lanka. This is in the Southern
                      Province of Sri Lanka boarding the Indian ocean. This park consists of five blocks and two
                      of them are open to the public. Yala was designated as a wildlife sanctuary in 1900. The
                      park is best known for its variety of wild animals. It is important for the conservation of
                      Sri Lankan elephants, Sri Lankan leopards, and aquatic birds.
                    </p>
                  </div>
                </div>
              </div>

              <div className="grid-itemA item2 ">
                <h2>Weligama</h2>
                <div className="row">
                  <div className="col-sm-5">
                    <img alt="" src={desp2} className={Number(windowDimensions.height)>Number(windowDimensions.width)?"rounded mx-auto d-block my-4 modelImageWeb":"rounded mx-auto d-block my-4 modelImageMobile"}/>
                  </div>
                  <div className="col-md-7">
                    <p className='fs-5'>
                      Weligama is a popular city in Sri Lanka, located on the island’s southern coast. The city
                      of Weligama is a popular tourist destination both national and international. Weligama
                      is a perfect place for fishing, surfing, and many other watersports like Weligama beach,
                      diving, whale watching. Also, the Kushta raja gala statue is one of the historical popular
                      tourist attractions of Weligama.
                    </p>
                  </div>
                </div>
              </div>

              <div className="grid-itemA item3 ">
                <h2>Unawatuna</h2>
                <div className="row">
                  <div className="col-sm-5">
                    <img alt="" src={desp3} className={Number(windowDimensions.height)>Number(windowDimensions.width)?"rounded mx-auto d-block my-4 modelImageWeb":"rounded mx-auto d-block my-4 modelImageMobile"}/>
                  </div>
                  <div className="col-md-7">
                    <p className='fs-5'>
                      Unawatuna is a coastal town in Galle district in Sri Lanka. It is famous for its beaches and
                      coral reefs. Jungle beach is a small beach that looks like it is located in I middle of a
                      jungle. In Unawatuna, you can explore underwater life through snorkeling, you can go
                      surfing, enjoy plates of seafood, boat rides, scuba diving. Unawatuna is a great place to
                      explore.
                    </p>
                  </div>
                </div>
              </div>

              <div className="grid-itemA item4 ">
                <h2>Hikkaduwa</h2>
                <div className="row">
                  <div className="col-sm-5">
                    <img alt="" src={desp4} className={Number(windowDimensions.height)>Number(windowDimensions.width)?"rounded mx-auto d-block my-4 modelImageWeb":"rounded mx-auto d-block my-4 modelImageMobile"}/>
                  </div>
                  <div className="col-md-7">
                    <p className='fs-5'>
                      Hikkaduwa is a small town located on the south coast of Sri Lanka. This is a perfect place
                      to enjoy lots of watersports like surfing, snorkeling, whale watching, diving, swimming,
                      riding glass boats, river safari, sailing. Also, Hikkaduwa is known for its coral gardens,
                      beaches, crystal clear waters, and an active nightlife. This is one of the main tourist
                      attractions places in Sri Lanka.
                    </p>
                  </div>
                </div>
              </div>

              <div className="grid-itemA item5 ">
                <h2>Galle Fort</h2>
                <div className="row">
                  <div className="col-sm-5">
                    <img alt="" src={desp5} className={Number(windowDimensions.height)>Number(windowDimensions.width)?"rounded mx-auto d-block my-4 modelImageWeb":"rounded mx-auto d-block my-4 modelImageMobile"}/>
                  </div>
                  <div className="col-md-7">
                    <p className='fs-5'>
                      This fort was built by the Portuguese in 1588 and declared a World Heritage Site in
                      1988. It is a historical, archaeological, and architectural monument that maintains a
                      polished appearance even after more than 432 years. This fort has a great history and
                      today has a multi-ethnic and multi-religious population. This fort is a beautiful traveling
                      location that can give you an experience, you never forget.
                    </p>
                  </div>
                </div>
              </div>

              <div className="grid-itemA item6 ">
                <h2>Mirissa</h2>
                <div className="row">
                  <div className="col-sm-5">
                    <img alt="" src={desp6} className={Number(windowDimensions.height)>Number(windowDimensions.width)?"rounded mx-auto d-block my-4 modelImageWeb":"rounded mx-auto d-block my-4 modelImageMobile"}/>
                  </div>
                  <div className="col-md-7">
                    <p className='fs-5'>
                      Mirissa is in Matara district south coast of Sri Lanka. This is a town that is famous for its
                      natural beaches. In here you can enjoy the beautiful beach, climb the parrot rock to
                      watch a perfect view of sunrise and sunset, go whale and dolphin watching, snorkel on
                      the Turtle Bay, or go surfing.
                    </p>
                  </div>
                </div>
              </div>

              <div className="grid-itemA item7 ">
                <h2>Tangalle</h2>
                <div className="row">
                  <div className="col-sm-5">
                    <img alt="" src={desp7} className={Number(windowDimensions.height)>Number(windowDimensions.width)?"rounded mx-auto d-block my-4 modelImageWeb":"rounded mx-auto d-block my-4 modelImageMobile"}/>
                  </div>
                  <div className="col-md-7">
                    <p className='fs-5'>
                      Tangalle is a large city located in Hambantota district, Southern Province, Sri Lanka. It is
                      a popular holiday destination on the south coast. In here you can visit lots of historic
                      places like Wewrukannala Vihara, Mulkirigala Raja Maha Vihara. Also, you can visit
                      Kalametiya Bird Sanctuary, Hummanaya blowhole (the only known blowhole in Sri Lanka
                      and considered to be the second-largest blowhole in the world), ad Parewella Natural
                      Swimming Area.
                    </p>
                  </div>
                </div>
              </div>

              <div className="grid-itemA item8 ">
                <h2>Bentota</h2>
                <div className="row">
                  <div className="col-sm-5">
                    <img alt="" src={desp8} className={Number(windowDimensions.height)>Number(windowDimensions.width)?"rounded mx-auto d-block my-4 modelImageWeb":"rounded mx-auto d-block my-4 modelImageMobile"}/>
                  </div>
                  <div className="col-md-7">
                    <p className='fs-5'>
                      Bentota is a coastal town located in Galle District, Southern Province, Sri Lanka. You can
                      enjoy Bentota by doing lots of things like paragliding, parasailing, watching dolphins,
                      visiting Kosgoda Turtle hatchery, enjoying Bentota beach, etc. Also, you can visit Brief
                      Garden which is designed by renowned and skilled Sri Lanka landscapes architecture
                      Bevis Bawa and his brother architect Jeffry Bawa. It is a beautiful garden that screeches
                      over five acres.
                    </p>
                  </div>
                </div>
              </div>

              <div className="grid-itemA item9 ">
                <h2>Udawalawe</h2>
                <div className="row">
                  <div className="col-sm-5">
                    <img alt="" src={desp9} className={Number(windowDimensions.height)>Number(windowDimensions.width)?"rounded mx-auto d-block my-4 modelImageWeb":"rounded mx-auto d-block my-4 modelImageMobile"}/>
                  </div>
                  <div className="col-md-7">
                    <p className='fs-5'>
                      Udawalawe is a small town located in Rathnapura in Sri Lanka. This town is the entry
                      point to Udawalawe National Park which is famous for its high population of Asian
                      elephants. Udawalawe has perfect places to camp, there are several rivers that make it
                      look very much appealing in terms of camping. This is a good place if one is looking for
                      an adventure and unforgettable experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}
